<template>
  <Content>
    <template v-slot:contentBody="wrap">
      <a-row
        :gutter="[16, 16]"
        type="flex"
        justify="space-between"
        align="bottom"
      >
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                v-model:value="searchState.searchKey"
                :placeholder="$t('logistics.please_input_plan_name_or_no')"
                :allowClear="true"
                style="width: 250px"
              />
            </a-col>
            <a-col>
              <a-input
                v-model:value="searchState.inOrOutWarehouseNo"
                :placeholder="
                  $t('warehouse.source_related_doc_no') +
                  '/' +
                  $t('warehouse.target_related_doc_no')
                "
                :allowClear="true"
                style="width: 250px"
              />
            </a-col>
            <a-col>
              <a-select
                v-model:value="searchState.destinationType"
                :allowClear="true"
                :placeholder="$t('logistics.destination_type')"
                style="width: 250px"
              >
                <a-select-option
                  v-for="(value, key) in destinationTypeEnum"
                  :key="key"
                  :title="$t($enumLangkey('destinationType', value))"
                  :value="value"
                >
                  {{ $t($enumLangkey("destinationType", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                v-model:value="searchState.isInsure"
                :allowClear="true"
                :placeholder="$t('logistics.purchase_insurance')"
                style="width: 250px"
              >
                <a-select-option :value="1">{{ $t('common.necessary') }}</a-select-option>
                <a-select-option :value="0">{{ $t('common.unnecessary') }}</a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-input-group compact>
                <a-select
                  allow-clear
                  show-search
                  style="width: 120px"
                  optionFilterProp="search-key"
                  v-model:value="searchState.fromCountryId"
                  @change="searchState.fromCityId = null"
                  :placeholder="$t('warehouse.originating_country')"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in countrys"
                    :key="item.id"
                    :title="getLanguageName(item) + '(' + item.ioS2 + ')'"
                    :search-key="item.cnName + item.enName + item.ioS2"
                    :phoneCountryCode="item.telCode"
                  >
                    {{ getLanguageName(item) }}({{ item.ioS2 }})
                  </a-select-option>
                </a-select>
                <a-select
                  v-model:value="searchState.fromCityId"
                  :placeholder="$t('logistics.city_search')"
                  :disabled="!searchState.fromCountryId"
                  allow-clear
                  show-search
                  @blur="citys = []"
                  style="width: 130px"
                  :filter-option="false"
                  optionFilterProp="search-key"
                  :not-found-content="searchCityLoading ? undefined : null"
                  @search="
                    (value) => {
                      searchState.fromCountryId
                        ? handleSearchCities(value, searchState.fromCountryId)
                        : '';
                    }
                  "
                >
                  <a-select-option
                    v-for="item in citys"
                    :search-key="item.cnName + item.enName + item.abbrCode"
                    :title="
                      getLanguageName(item) +
                      '(' +
                      getLanguageName({
                        cnName: item.provinceCnName,
                        enName: item.provinceEnName,
                      }) +
                      ')'
                    "
                    :key="item.id"
                  >
                    {{ getLanguageName(item) }}({{ item.provinceAbbrCode }})
                  </a-select-option>
                  <template v-if="searchCityLoading" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                </a-select>
              </a-input-group>
            </a-col>
            <a-col>
              <a-input-group compact>
                <a-select
                  :allowClear="true"
                  show-search
                  style="width: 120px"
                  :loading="countryLoading"
                  optionFilterProp="search-key"
                  v-model:value="searchState.toCountryId"
                  @change="searchState.toCityId = null"
                  :placeholder="$t('common.destination_country')"
                >
                  <a-select-option
                    :value="item.id"
                    v-for="item in countrys"
                    :key="item.id"
                    :title="getLanguageName(item) + '(' + item.ioS2 + ')'"
                    :search-key="item.cnName + item.enName + item.ioS2"
                    :phoneCountryCode="item.telCode"
                  >
                    {{ getLanguageName(item) }}({{ item.ioS2 }})
                  </a-select-option>
                </a-select>
                <a-select
                  v-model:value="searchState.toCityId"
                  :placeholder="$t('logistics.city_search')"
                  allow-clear
                  :disabled="!searchState.toCountryId"
                  show-search
                  @blur="citys = []"
                  style="width: 130px"
                  :filter-option="false"
                  optionFilterProp="search-key"
                  :not-found-content="searchCityLoading ? undefined : null"
                  @search="
                    (value) => {
                      searchState.toCountryId
                        ? handleSearchCities(value, searchState.toCountryId)
                        : '';
                    }
                  "
                >
                  <a-select-option
                    v-for="item in citys"
                    :search-key="item.cnName + item.enName + item.abbrCode"
                    :title="
                      getLanguageName(item) +
                      '(' +
                      getLanguageName({
                        cnName: item.provinceCnName,
                        enName: item.provinceEnName,
                      }) +
                      ')'
                    "
                    :key="item.id"
                    >{{ getLanguageName(item) }}({{
                      item.provinceAbbrCode
                    }})</a-select-option
                  >
                  <template v-if="searchCityLoading" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                </a-select>
              </a-input-group>
            </a-col>
            <a-col>
              <a-select
                v-model:value="searchState.planStatus"
                :allowClear="true"
                :placeholder="$t('logistics.status')"
                style="width: 250px"
              >
                <a-select-option
                  v-for="(value, key) in transportStatusEnum"
                  :key="key"
                  :title="$t($enumLangkey('transportStatus', value))"
                  :value="value"
                >
                  {{ $t($enumLangkey("transportStatus", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                v-model:value="searchState.isLoseMoney"
                :allowClear="true"
                :placeholder="$t('warehouse.whether_to_compensate')"
                style="width: 250px"
              >
                <a-select-option
                  v-for="(item, index) in refundAndCompensationStatusList"
                  :key="index"
                  :title="$t(item.label)"
                  :value="item.value"
                  :data-index="index"
                >
                  {{ $t(item.label) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-range-picker
                style="width: 250px"
                @change="handleSelectedDate"
              />
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleChange">{{
                $t("common.query")
              }}</a-button>
            </a-col>
            <a-col>
              <a-button
                :loading="exportLoading"
                @click="handleExport"
                type="ghost"
                >{{ $t("common.export") }}</a-button
              >
            </a-col>
            <a-col>
              <a-button
                @click="handleImport"
                type="ghost"
                >{{ $t("menu.transport_import") }}</a-button
              >
            </a-col>
            <a-col>
              <a-button type="primary" :disabled="selectedRows.length === 0" @click="handleExportWarehouseReceipt">{{$t('logistics.print_warehouse_receipt')}}</a-button>
            </a-col>
            <a-col>
              <a-button type="primary" :disabled="selectedRows.length === 0 || disabledPrintBill" @click="handlePrintBill">{{$t('logistics.print_bill')}}</a-button>
            </a-col>
            <a-col>
              <a-button type="primary" :loading="listLoading" :disabled="selectedRows.length === 0 || disabledPrintBill" @click="handleExportBill">{{$t('logistics.export_bill')}}</a-button>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleCreatePlan" ghost>{{
            $t("common.create")
          }}</a-button>
        </a-col>
      </a-row>
      <div class="inventory-table mt-3">
        <a-table
          :columns="columns"
          :data-source="planList"
          :scroll="{ x: 500, y: wrap.contentHeight - 210 }"
          :pagination="false"
          :row-selection="{
            columnTitle: tableColumnTitle,
            selectedRowKeys: selectedRowKeys,
            onChange: onTableSelectedChange,
            getCheckboxProps: (record) => ({ disabled: record.disabled })
          }"
          size="small"
          :loading="listLoading"
          :row-key="(record) => record.planId"
          class="product-table"
        >
          <template #planNameAndNo>
            <div>
              {{ $t("logistics.transportation_plan_name") }}
            </div>
            <div>
              {{ $t("logistics.transportation_plan_number") }}
            </div>
          </template>
          <template #transportRouteAndDestinationType>
            <div>{{ $t("logistics.transport_route") }}</div>
            <div>{{ $t("logistics.destination_type") }}</div>
          </template>
          <template #planNameAndNoCustom="{ record }">
            <div>{{ record.planName }}</div>
            <div>{{ record.planNo }}</div>
          </template>

          <template #transportRouteAndDestinationTypeCustom="{ record }">
            <a-row :gutter="[8,8]">
              <a-col>
                {{ $t($enumLangkey("transportRoutes", record.transportRoute)) }}
                <div>
                  {{ $t($enumLangkey("destinationType", record.destinationType)) }}
                </div>
              </a-col>
              <a-col v-if="record.isUploadHsInfo" :span="24">
                <a-tag color="processing">{{$t('warehouse.declare')}}</a-tag>
              </a-col>
            </a-row>
          </template>

          <template #startCountryCityTitle>
            <div>{{ $t("warehouse.source_country") }}</div>
            <div>{{ $t("logistics.city") }}</div>
          </template>
          <template #startCountryCityCustom="{ record }">
            <div>
              {{
                getLanguageName({
                  cnName: record.formCountryCnName,
                  enName: record.formCountryEnName,
                })
              }}
            </div>
            <div>
              {{
                getLanguageName({
                  cnName: record.formCityCnName,
                  enName: record.formCityEnName,
                })
              }}
            </div>
          </template>

          <template #destinationCountryCityTitle>
            <div>{{ $t("common.destination_country") }}</div>
            <div>{{ $t("logistics.city") }}</div>
          </template>
          <template #destinationCountryCityCustom="{ record }">
            <div>
              {{
                getLanguageName({
                  cnName: record.toCountryCnName,
                  enName: record.toCountryEnName,
                })
              }}
            </div>
            <div>
              {{
                getLanguageName({
                  cnName: record.toCityCnName,
                  enName: record.toCityEnName,
                })
              }}
            </div>
          </template>

          <template #transportCountTitle>
            <div>{{ $t("logistics.transport_count") }}</div>
            <div>{{ $t("logistics.transport_box_count") }}</div>
          </template>
          <template #transportCountCustomer="{ record }">
            <div>
              {{ record.transportAmount }}
              <span v-if="record.cancelTransportCount > 0">
                <a-tooltip :title="$t('warehouse.cancel_count')">
                  (-{{ record.cancelTransportCount }})
                </a-tooltip>
              </span> Unit
            </div>
            <div>
              {{ record.transportBoxCount }}
              <span v-if="record.cancelTransportBoxCount > 0">
                <a-tooltip :title="$t('warehouse.cancel_box_count')">
                  (-{{ record.cancelTransportBoxCount }})
                </a-tooltip>
              </span> {{ $t('warehouse.box') }}
            </div>
          </template>

          <template #transportBoxesTotalTitle>
            <div>{{ $t("logistics.forecast_volume") }}</div>
            <div>{{ $t("logistics.forecast_weight") }}</div>
          </template>
          <template #transportBoxesTotalCustomer="{ record }">
            <div>
              <span v-if="record.transportBoxesTotal">{{ cmCubicToM(record.transportBoxesTotal?.totalContainerVolume ?? 0) }}m<sup>3</sup></span>
              <span v-else>-</span>
            </div>
            <div>
              <span v-if="record.transportBoxesTotal">{{ gToKg(record.transportBoxesTotal?.totalContainerWeight ?? 0) }}kg</span>
              <span v-else>-</span>
            </div>
          </template>

          <template #chargeableTitle>
            <div>{{ $t("logistics.billing_volume") }}</div>
            <div>{{ $t("logistics.billing_weight") }}</div>
          </template>
          <template #chargeableCustomer="{ record }">
            <div>
              <span v-if="record.chargeableInfo && record.chargeableInfo.logisticsCalcMethod === logisticsCalcMethodEnum.volume">{{ cmCubicToM(record.chargeableInfo?.chargeableVolume ?? 0) }}m<sup>3</sup></span>
              <span v-else>-</span>
            </div>
            <div>
              <span v-if="record.chargeableInfo && record.chargeableInfo.logisticsCalcMethod === logisticsCalcMethodEnum.weight">{{ gToKg(record.chargeableInfo?.chargeableWeight ?? 0) }}kg</span>
              <span v-else>-</span>
            </div>
          </template>

          <template #insuranceCustomer="{ record }">
            {{ record.isInsure === true ? $t("common.necessary") : record.isInsure === false ? $t("common.unnecessary") : '-' }}
          </template>
          
          <template #costCustomer="{ record }">
            <span v-if="record.chargeableInfo && record.chargeableInfo.fee">
              {{ $filters.formatCurrencyAmount(record.chargeableInfo.fee.currencySymbol, record.chargeableInfo.fee.totalPrice, false) }}
            </span>
            <span v-else>-</span>
          </template>

          <template #relevanceInNoAndOutNoTitle>
            <div>{{ $t("warehouse.source_related_doc_no") }}</div>
            <div>{{ $t("warehouse.target_related_doc_no") }}</div>
          </template>

          <template #relevanceInNoAndOutNoCustom="{ record }">
            <div>
              <CPlanNumberItem :no="record.relevanceInNo || record.relevanceOutNo"></CPlanNumberItem>
            </div>
            <div>
              <CPlanNumberItem :no="record.toInWarehouseNo"></CPlanNumberItem>
            </div>
          </template>

          <template #logisticsAndSiteTitle>
            <div>{{ $t("enum.warehouse_type_4") }}</div>
            <div>{{ $t("logistics.receiving_site") }}</div>
          </template>

          <template #logisticsAndSiteCustom="{ record }">
            <div>{{ record.logisticsWarehouseNo }}</div>
            <div>{{ record.collectionOutletsName }}</div>
          </template>

          <template #statusCustom="{ record }">
            <a-space direction="vertical">
              <span>{{ $t($enumLangkey("transportStatus", record.status)) }}</span>
              <div v-if="record.isPayOnArrival && record.logisticsNode">
                <a-tag
                  color="blue"
                  v-if="
                    record.logisticsNode.accountingPeriodCalculationWay ===
                    logisticsAccountingPeriodCalculationWayEnum.nextMonth
                  "
                >
                  {{
                    $t(
                      $enumLangkey(
                        "logisticsNode",
                        record.logisticsNode.logisticsNode
                      )
                    )
                  }}, {{ $t("logistics.next_month") }},
                  {{ record.logisticsNode.accountingPeriodDays
                  }}{{ $t("common.days") }}
                </a-tag>
                <a-tag color="blue" v-else
                  >{{
                    $t(
                      $enumLangkey(
                        "logisticsNode",
                        record.logisticsNode.logisticsNode
                      )
                    )
                  }}, {{ record.logisticsNode.accountingPeriodDays
                  }}{{ $t("common.days") }}</a-tag
                >
              </div>
              <a-tag
                color="success"
                v-if="
                  record.status == transportStatusEnum.awaitVisitFetch ||
                  record.status == transportStatusEnum.visitFetching
                "
              >
                {{ $t("logistics.door_took") }}
              </a-tag>
              <a-tooltip
                v-if="record.status == transportStatusEnum.visitFetching"
              >
                <template #title>
                  {{ $t("logistics.estimated_time_of_arrival") }}:
                  {{ $filters.utcToCurrentTime(record.expectArriveTime) }}
                </template>
                <ClockCircleOutlined class="text-warning" />
              </a-tooltip>
              <a-tag color="orange" v-if="record.loseMoney > 0"
                >{{ $t("warehouse.compensation") }} {{ record.currencySymbol
                }}{{ $filters.amountToFixed2(record.loseMoney) }}</a-tag
              >
            </a-space>
            <PaymentCountdown
              v-if="record.status === transportStatusEnum.awaitPay"
              :value="record.countdownPaidSeconds"
              @finish="handleSearch"
            />
          </template>

          <template #createTimeAndUpdateTimeTitle>
            <div>{{ $t("warehouse.creation_time") }}</div>
            <div>{{ $t("warehouse.update_time") }}</div>
          </template>
          <template #createTimeAndUpdateTimeCustom="{ record }">
            <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
            <div>{{ $filters.utcToCurrentTime(record.updateTime) }}</div>
          </template>

          <template #operateCustom="{ record }">
            <a-dropdown-button @click="handleEditOrDetails(record)">
              <span
                v-if="record.createPlanStatus == planDraftStatus.completeStep1"
              >
                {{ $t("common.edit") }}
              </span>
              <span
                v-else-if="
                  record.createPlanStatus == planDraftStatus.completeStep2 ||
                  record.createPlanStatus == planDraftStatus.completeStep3
                "
              >
                {{ $t("common.edit") }}
              </span>
              <span
                v-else-if="
                  record.createPlanStatus == planDraftStatus.completeStep4
                "
              >
                {{ $t("common.details") }}
              </span>
              <template #overlay>
                <a-menu @click="handleMenuClick">
                  <a-menu-item
                    :disabled="
                      !(
                        record.logisticsType != logisticsWayEnum.commercialExpress
                        && record.status >= transportStatusEnum.inTransit
                        && record.status <= transportStatusEnum.haveArrived
                      )
                    "
                    :key="3"
                    :record="record"
                  >
                    {{ $t("warehouse.track_info") }}
                  </a-menu-item>

                  <a-menu-item :key="4" :record="record">
                    {{ $t("warehouse.create_in_warehouse_plan") }}
                  </a-menu-item>
                  <a-menu-item
                    v-if="record.createPlanStatus == planDraftStatus.completeStep4"
                    :key="5"
                    :record="record"
                    :disabled="record.status === transportStatusEnum.canceled || record.bulkPrintLoading"
                  >
                    {{ $t("common.bulk_print_box_label") }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown-button>
          </template>
        </a-table>
      </div>
      <a-row type="flex" justify="end" :gutter="32" class="pt-2 pb-2">
        <a-col><small>{{ $t('warehouse.box_amount') }}: {{ tableTotalState.boxes }}</small></a-col>
        <a-col><small>{{ $t('logistics.forecast_volume') }}: {{ tableTotalState.forecastVolume }}m<sup>3</sup></small></a-col>
        <a-col><small>{{ $t('logistics.forecast_weight') }}: {{ tableTotalState.forecastWeight }}kg</small></a-col>
        <a-col><small>{{ $t('logistics.billing_volume') }}: {{ tableTotalState.billingVolume }}m<sup>3</sup></small></a-col>
        <a-col><small>{{ $t('logistics.billing_weight') }}: {{ tableTotalState.billingWeight }}kg</small></a-col>
        <a-col style="display: flex; align-items: center;">
          <a-popover v-if="tableTotalState.currencySymbolList.length > 0">
            <template #content>
              <a-row :gutter="[0, 8]" style="max-width: 150px;">
                <a-col v-for="(item, index) in tableTotalState.currencySymbolList" :key="index">
                  <a-tag>{{ $filters.formatCurrencyAmount(item, tableTotalState.totalCostList[index], false) }}</a-tag>
                </a-col>
              </a-row>
            </template>
            <div class="total-cost">
              <small>
                {{ $t('logistics.total_cost') }}: 
                <span class="total-cost-item" v-for="(item, index) in tableTotalState.currencySymbolList" :key="index">{{ $filters.formatCurrencyAmount(item, tableTotalState.totalCostList[index], false) }}</span>
                <span v-if="tableTotalState.currencySymbolList.length === 0">-</span>
              </small>
            </div>
          </a-popover>
          <small v-else>{{ $t('logistics.total_cost') }}: -</small>
        </a-col>
      </a-row>

      <CTrackInfoModal
        v-model:visible="trackModal.visible"
        :list="trackModal.list"
         dateFormat="yyyy-MM-DD"
        :loading="trackModal.loading"
      >
        <template v-slot:numberName>
          {{ $t("logistics.transportation_plan_number") }}
        </template>
        <template v-slot:no>
          {{ trackModal.no }}
        </template>
      </CTrackInfoModal>

      <a-modal
        v-model:visible="createPlanModal.visible"
        :title="$t('warehouse.create_in_warehouse_plan')"
        :maskClosable="false"
        :cancelText="$t('common.cancel')"
        :okText="$t('common.confirm')"
        @ok="handleCreatePlanModalOk"
      >
        <a-form
          ref="refForm"
          :label-col="{ xs: 5, sm: 5 }"
          :wrapper-col="{ xs: 15, sm: 15 }"
          :rules="rules"
          :model="createPlanModal"
        >
          <!-- 仓库类型 -->
          <a-form-item
            name="selectedWarehouseType"
            :label="$t('warehouse.warehouse_type')"
          >
            <a-select
              v-model:value="createPlanModal.selectedWarehouseType"
              :placeholder="$t('common.please_select')"
              @change="handleGetWarehouseInfos"
            >
              <a-select-option
                :value="warehouseTypeEnum.storageWarehouse"
                :data-index="0"
              >
                {{
                  $t(
                    $enumLangkey(
                      "warehouseType",
                      warehouseTypeEnum.storageWarehouse
                    )
                  )
                }}
              </a-select-option>
              <a-select-option
                :value="warehouseTypeEnum.consignmentWarehouse"
                :data-index="1"
              >
                {{
                  $t(
                    $enumLangkey(
                      "warehouseType",
                      warehouseTypeEnum.consignmentWarehouse
                    )
                  )
                }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <!-- 入库目标仓库 -->
          <a-form-item
            :label="$t('warehouse.target_warehouse')"
            name="selectedTargetWarehouse"
          >
            <a-select
              :disabled="!createPlanModal.selectedWarehouseType"
              v-model:value="createPlanModal.selectedTargetWarehouse"
              :showSearch="true"
              optionFilterProp="search-key"
              :loading="createPlanModal.targetWarehousesLoading"
              :placeholder="$t('common.please_select')"
            >
              <a-select-option
                v-for="item in createPlanModal.targetWarehouses"
                :title="item.warehouseNo + '(' + item.warehouseName + ')'"
                :search-key="item.warehouseNo + item.warehouseName"
                :key="item.id"
                :value="item.id"
              >
                {{ item.warehouseNo }} ({{ item.warehouseName }})
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-modal>
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import {
  h,
  computed,
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  onActivated,
  ref,
} from "vue";
import {
  Row,
  Col,
  Tag,
  Spin,
  Table,
  Input,
  Tooltip,
  Select,
  Button,
  Form,
  DatePicker,
  Space,
  message,
  Dropdown,
  Menu,
  Modal,
  Popover,
  Checkbox,
} from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CPlanNumberItem from "@/views/components/CPlanNumberItem.vue";
import CTrackInfoModal from "@/views/components/CTrackInfoModal.vue";
import PaymentCountdown from "./components/PaymentCountdown.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import localPrint from "@/utils/localPrint.js";
import { downloadFile } from "@/utils/downloader";
import filters from "@/filters";
import {
  getName,
  getAddressByLanguageV2,
  currentTimeToUtc,
  dateString,
  cmCubicToM,
  gToKg,
} from "@/utils/general";
import {
  getTransportPlanList,
  exportTransportPlan,
  createPlanModalOk,
  batchSeInWarehouseOrder,
  printTransportBill,
  exportTransportBill,
  getTrackList,
} from "@/api/modules/transportation/index";
import { getGlobalCountrys, searchCities, getWarehouses } from "@/api/modules/common/index";
import { batchPrintBoxOfPlanTransport } from "@/api/modules/printUrl/pdf.js"
import {
  warehouseType as warehouseTypeEnum,
  destinationType as destinationTypeEnum,
  transportStatus as transportStatusEnum,
  logisticsWay as logisticsWayEnum,
  logisticsAccountingPeriodCalculationWay as logisticsAccountingPeriodCalculationWayEnum,
  logisticsCalcMethod as logisticsCalcMethodEnum,
} from "@/enum/enum.json";

export default defineComponent({
  name: "transport_plan",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ARangePicker: DatePicker.RangePicker,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ATag: Tag,
    ATooltip: Tooltip,
    ASpin: Spin,
    ASpace: Space,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AModal: Modal,
    ATooltip: Tooltip,
    APopover: Popover,
    Content,
    CPager,
    CTrackInfoModal,
    CPlanNumberItem,
    PaymentCountdown,
  },
  setup() {
    const router = useRouter();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const refForm = ref();

    const columns = [
      {
        fixed: "left",
        width: 150,
        slots: {
          title: "planNameAndNo",
          customRender: "planNameAndNoCustom",
        },
      },
      {
        width: 150,
        slots: {
          title: "relevanceInNoAndOutNoTitle",
          customRender: "relevanceInNoAndOutNoCustom",
        },
      },
      {
        width: 130,
        slots: {
          title: "transportRouteAndDestinationType",
          customRender: "transportRouteAndDestinationTypeCustom",
        },
      },
      {
        width: 100,
        slots: {
          title: "startCountryCityTitle",
          customRender: "startCountryCityCustom",
        },
      },
      {
        width: 100,
        slots: {
          title: "destinationCountryCityTitle",
          customRender: "destinationCountryCityCustom",
        },
      },
      {
        width: 80,
        slots: {
          title: "transportCountTitle",
          customRender: "transportCountCustomer",
        },
      },
      {
        width: 100,
        slots: {
          title: "transportBoxesTotalTitle",
          customRender: "transportBoxesTotalCustomer",
        },
      },
      {
        width: 100,
        slots: {
          title: "chargeableTitle",
          customRender: "chargeableCustomer",
        },
      },
      {
        title: () => vueI18n.t("logistics.purchase_insurance"),
        width: 100,
        slots: {
          customRender: "insuranceCustomer",
        },
      },
      {
        title: () => vueI18n.t("logistics.total_cost"),
        width: 100,
        slots: {
          customRender: "costCustomer",
        },
      },
      {
        width: 150,
        slots: {
          title: "logisticsAndSiteTitle",
          customRender: "logisticsAndSiteCustom",
        },
      },
      {
        title: () => vueI18n.t("logistics.status"),
        width: 120,
        slots: {
          customRender: "statusCustom",
        },
      },
      {
        width: 150,
        slots: {
          title: "createTimeAndUpdateTimeTitle",
          customRender: "createTimeAndUpdateTimeCustom",
        },
      },
      {
        title: () => vueI18n.t("logistics.subsequent_steps"),
        width: 120,
        fixed: "right",
        slots: {
          customRender: "operateCustom",
        },
      },
    ];

    const planDraftStatus = {
      completeStep1: 1, //第一步保存
      completeStep2: 2, //第二步保存
      completeStep3: 3, //第三步保存未下单
      completeStep4: 4, //第三步保存已下单
    };
    const state = reactive({
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      listLoading: false,
      exportLoading: false,
      countryLoading: false,
      searchCityLoading: false,
      countrys: [],
      citys: [],
      planStatus: null,
      isLoseMoney: null,

      searchState: {
        searchKey: null,
        destinationType: null,
        isInsure: null,
        fromCountryId: null,
        fromCityId: null,
        toCountryId: null,
        toCityId: null,
        planStatus: null,
        isLoseMoney: null,
        startCreationTime: null,
        endCreationTime: null,
        inOrOutWarehouseNo: null,
      },
      searchStateCache: {},
      planList: [],
      selectedRowKeys: [],
      selectedRows: [],
      refundAndCompensationStatusList: [
        {
          label: "common.yes",
          value: 1,
        },
        {
          label: "common.no",
          value: 0,
        },
      ],
    });

    const tableTotalState = reactive({
      boxes: 0,
      forecastVolume: 0,
      forecastWeight: 0,
      billingVolume: 0,
      billingWeight: 0,
      currencySymbolList: [],
      totalCostList: [],
    })

    const getTotalCostStr = computed(() => {
      let str = vueI18n.t("logistics.total_cost") + ": ";
      if (tableTotalState.currencySymbolList.length > 0) {
        tableTotalState.currencySymbolList.forEach((item, index) => {
          if (index !== 0) {
            str += ", ";
          }
          str += filters.formatCurrencyAmount(item, tableTotalState.totalCostList[index], false);
        })
      } else {
        str += "-";
      }
      return str;
    })

    const getTotalData = () => {
      let [boxes, forecastVolume, forecastWeight, billingVolume, billingWeight] = new Array(5).fill(0);
      let currencySymbolList = Array.from(new Set(state.planList.map(item => item.chargeableInfo?.fee?.currencySymbol).filter(Boolean)));
      let totalCostList = new Array(currencySymbolList.length).fill(0);
      state.planList.forEach(item => {
        boxes += item.transportBoxCount ?? 0;
        forecastVolume += item.transportBoxesTotal?.totalContainerVolume ?? 0;
        forecastWeight += item.transportBoxesTotal?.totalContainerWeight ?? 0;
        billingVolume += item.chargeableInfo?.chargeableVolume ?? 0;
        billingWeight += item.chargeableInfo?.chargeableWeight ?? 0;
        if (item.chargeableInfo?.fee?.totalPrice) {
          let symbolIndex = currencySymbolList.findIndex(symbol => item.chargeableInfo?.fee?.currencySymbol === symbol);
          if (symbolIndex > -1) {
            totalCostList[symbolIndex] += item.chargeableInfo.fee?.totalPrice ?? 0;
          }
        }
      });
      
      tableTotalState.boxes = boxes;
      tableTotalState.forecastVolume = cmCubicToM(forecastVolume);
      tableTotalState.forecastWeight = gToKg(forecastWeight);
      tableTotalState.billingVolume = cmCubicToM(billingVolume);
      tableTotalState.billingWeight = gToKg(billingWeight);
      tableTotalState.currencySymbolList = currencySymbolList;
      tableTotalState.totalCostList = totalCostList.map(item => item.toFixed(2));
    }

    const handleExportWarehouseReceipt = () => {
      let printUrl = batchSeInWarehouseOrder({ planIds: state.selectedRowKeys });
      localPrint(printUrl, "pdf");
    }

    const handlePrintBill = () => {
      let printUrl = printTransportBill({ planIds: state.selectedRowKeys });
      localPrint(printUrl, "pdf");
    }

    const handleExportBill = () => {
      state.listLoading = true;
      let url = exportTransportBill({ planIds: state.selectedRowKeys })
      downloadFile(url, `transportation_plan_bill_list_${dateString()}.xlsx`, "GET")
        .then(() => {
          state.listLoading = false;
        })
        .catch(() => {
          state.listLoading = false;
        })
    }

    const isDisabledRowSelect =  (record, selectedRow) => {
      if (record.status === transportStatusEnum.planing || record.status === transportStatusEnum.canceled) {
        return true;
      }
      if (selectedRow) {
        return !(record.shopNo === selectedRow.shopNo && record.logisticsWarehouseId === selectedRow.logisticsWarehouseId);
      } else {
        return false;
      }
    }

    const handleSetTableDisabled = (selectedRow) => {
      let cacheTable = state.planList.map(item => {
        item.disabled = isDisabledRowSelect(item, selectedRow);
        return item;
      });
      state.planList = cacheTable;
     }

    const onTableSelectedChange = (selectedRowKeys, selectedRows) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;

      let selectedRow = selectedRows.length > 0 ? selectedRows[0] : null;
      handleSetTableDisabled(selectedRow);
    }

    const totalNumberAvailable = computed(() => {
      return state.planList.filter(item => !item.disabled).length;
    })

    const tableColumnTitle = () => {
      return h(
        Checkbox, {
          onChange: (e) => {
            let checked = e.target.checked;
            
            if (checked) {
              if (state.selectedRowKeys.length === 0) {
                // 未有选中项，判断第一个可选项
                let firstAvailableItem = state.planList.find(item => item.status !== transportStatusEnum.planing && item.status !== transportStatusEnum.canceled);
                // 根据第一项标记所有可选项
                handleSetTableDisabled(firstAvailableItem);
              }
              // 然后把所有可选的标记为选中
              state.selectedRowKeys = state.planList.filter(item => !item.disabled).map(item => item.planId);
              state.selectedRows = state.planList.filter(item => !item.disabled);
            } else {
              state.selectedRowKeys = [];
              state.selectedRows = [];
              handleSetTableDisabled(null);
            }
          },
          indeterminate: state.selectedRowKeys.length > 0 && state.selectedRowKeys.length < totalNumberAvailable.value,
          checked: state.selectedRowKeys.length === totalNumberAvailable.value,
        }
      );
    }

    const handleSelectedDate = (e) => {
      let startCreationTime = null;
      let endCreationTime = null;
      if (e.length === 2) {
        startCreationTime = currentTimeToUtc(
          e[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endCreationTime = currentTimeToUtc(e[1]?.format("YYYY-MM-DD 00:00:00"));
      }
      state.searchState.startCreationTime = startCreationTime;
      state.searchState.endCreationTime = endCreationTime;
    };

    const handleExport = () => {
      const searchData = Object.assign({}, state.searchStateCache);
      searchData.isInsure = searchData.isInsure === 1 ? true : searchData.isInsure === 0 ? false : null;
      searchData.isLoseMoney =
        searchData.isLoseMoney === 1
          ? true
          : searchData.isLoseMoney === 0
          ? false
          : null;

      state.exportLoading = true;
      let url = exportTransportPlan();
      downloadFile(
        url,
        `transport_plan_${dateString()}.csv`,
        "POST",
        searchData
      )
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
        })
        .catch((err) => {})
        .finally(() => {
          state.exportLoading = false;
        });
    };

    // 批量导入
    const handleImport = () => {
      router.push({ path: "/transport/import" });
    }

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      handleSearch();
    };

    const handleChange = () => {
      state.pageData.skipCount = 0;
      state.pageData.currentIndex = 1;
      Object.assign(state.searchStateCache, state.searchState);
      handleSearch();
    };

    const handleCreatePlan = async () => {
      router.push({ path: "/transport/createplan" });
    };

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    };

    const handleSearch = async () => {
      const searchData = Object.assign(
        {},
        state.pageData,
        state.searchStateCache,
        {
          isNeedTransportBoxesTotal: true,
        }
      );
      searchData.isInsure = searchData.isInsure === 1 ? true : searchData.isInsure === 0 ? false : null;
      searchData.isLoseMoney =
        searchData.isLoseMoney === 1
          ? true
          : searchData.isLoseMoney === 0
          ? false
          : null;

      state.listLoading = true;

      getTransportPlanList(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            state.planList = items.map((x, index) => {
              return {
                key: index + 1,
                planId: x.id,
                planName: x.planName,
                planNo: x.planNo,
                transportRoute: x.transportRoutes,
                destinationType: x.destinationType,
                formCountryCnName: x.fromAddress?.countryCnName,
                formCountryEnName: x.fromAddress?.countryEnName,
                formCityCnName: x.fromAddress?.cityCnName,
                formCityEnName: x.fromAddress?.cityEnName,
                toCountryCnName: x.toAddress?.countryCnName,
                toCountryEnName: x.toAddress?.countryEnName,
                toCityCnName: x.toAddress?.cityCnName,
                toCityEnName: x.toAddress?.cityEnName,
                transportAmount: x.transportCount,
                cancelTransportCount: x.cancelTransportCount,
                transportBoxCount: x.transportBoxCount,
                cancelTransportBoxCount: x.cancelTransportBoxCount,
                relevanceOutNo: x.outWarehouseNo ?? "-",
                logisticsWarehouseNo: x.logisticsWarehouseNo ?? "-",
                logisticsWarehouseId: x.logisticsWarehouseId,
                collectionOutletsName: x.collectionOutletInfo?.collectionOutletsName ?? "-",
                relevanceInNo: x.inWarehouseNo,
                toInWarehouseNo: x.toInWarehouseNo,
                status: x.planStatus,
                countdownPaidSeconds: x.countdownPaidSeconds,
                logisticsType: x.logisticsType,
                creationTime: x.creationTime,
                updateTime: x.lastModificationTime,
                createPlanStatus: x.planDraftStatus,
                expectArriveTime: x.expectArriveTime,
                isPayOnArrival: x.isPayOnArrival,
                currencySymbol: x.currencySymbol,
                loseMoney: x.loseMoney,
                logisticsNode: x.logisticsNode,
                isCreateInWarehousePlan: x.isCreateInWarehousePlan,
                isUploadHsInfo: x.isUploadHsInfo,
                transportBoxesTotal: x.transportBoxesTotal,
                chargeableInfo: x.chargeableInfo,
                isInsure: x.isInsure,
                disabled: (x.planStatus === transportStatusEnum.planing || x.planStatus === transportStatusEnum.canceled) ? true: false,
              };
            });
            state.pageData.totalCount = parseInt(totalCount);
          } else {
            state.pageData.totalCount = 0;
          }
        })
        .catch(() => {})
        .finally(() => {
          state.selectedRowKeys = [];
          state.selectedRows = [];
          state.listLoading = false;
          getTotalData();
        });
    };

    const funcGetCountrys = async () => {
      state.countryLoading = true;
      getGlobalCountrys()
        .then((res) => {
          if (res.result) {
            state.countrys = res.result;
          }
        })
        .finally(() => {
          state.countryLoading = false;
        });
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    let searchCityTimer = null;
    const searchDebounceTime = 500;
    const handleSearchCities = (city, country) => {
      state.searchCityLoading = true;
      state.citys = [];
      if (searchCityTimer) {
        clearTimeout(searchCityTimer);
      }
      searchCityTimer = setTimeout(() => {
        searchCities({
          countryId: country,
          searchKey: city,
          skipCount: 0,
          maxResultCount: 10,
        })
          .then((res) => {
            let { items } = res.result;
            state.citys = items;
          })
          .finally(() => {
            state.searchCityLoading = false;
          });
      }, searchDebounceTime);
    };

    const trackModal = reactive({
      visible: false,
      loading: false,
      planId: null,
      warehouseId: null,
      list: [],
      no: null,
    });

    const handleShowTrackModal = (record) => {
      trackModal.visible = true;
      trackModal.planId = record.planId;
      trackModal.warehouseId = record.logisticsWarehouseId;
      trackModal.no = record.planNo;
      funcGetTrackInfo();
    };

    const funcGetTrackInfo = () => {
      trackModal.visible = true;
      trackModal.loading = true;
      getTrackList({ ...trackModal })
        .then(({ result }) => {
          trackModal.list = result ?? [];
        })
        .finally(() => {
          trackModal.loading = false;
        });
    };

    const handleEditOrDetails = (record) => {
      //有编辑就无详情
      if (record.createPlanStatus == planDraftStatus.completeStep1) {
        router.push({
          name: "transport_set_plan_info",
          params: { id: record.planId },
        });
      } else if (
        record.createPlanStatus == planDraftStatus.completeStep2 ||
        record.createPlanStatus == planDraftStatus.completeStep3
      ) {
        router.push({
          name: "transport_confirm_plan",
          params: { id: record.planId },
        });
      } else if (record.createPlanStatus == planDraftStatus.completeStep4) {
        router.push({
          name: "transport_plan_details",
          params: { id: record.planId },
        });
      }
    };

    const handleBulkPrint = async (record) => {
      try {
        record.bulkPrintLoading = true;
        let boxLabelPdfUrls = batchPrintBoxOfPlanTransport(record.planId);
        await localPrint(boxLabelPdfUrls, "pdf");
      } catch (error) {
      } finally {
        record.bulkPrintLoading = false;
      }
    }

    const handleMenuClick = (e) => {
      let { key, item } = e;
      let { record } = item;
      
      switch (key) {
        case 3:
          // 轨迹信息
          handleShowTrackModal(record);
          break;
        case 4:
          // 创建入库计划
          handleShowCreatePlanModal(record);
          break;
        case 5:
          // 批量打印箱标
          handleBulkPrint(record);
          break;
        default:
          break;
      }
    }

    //----------------------创建入库计划Modal start---------------------------------------
    const createPlanModal = reactive({
      visible: false,
      record: null,
      loading: false,
      selectedWarehouseType: null,
      selectedTargetWarehouse: null,
      targetWarehouses: [],
      targetWarehousesLoading: false,
      // showTab:''//当看到这段注释的时候,不显示的话就删除掉, 需要的话就看看创建运输计划代码, 
    });

    const handleShowCreatePlanModal = (record) => {
      createPlanModal.visible = true;
      createPlanModal.record = record;
      refForm.value?.resetFields();
      refForm.value?.clearValidate();
    };

    const handleCreatePlanModalOk = () => {
      refForm.value
        .validate()
        .then(() => {
          createPlanModal.loading = true;
          return createPlanModalOk(
            createPlanModal.record.planId,
            createPlanModal.selectedTargetWarehouse
          );
        })
        .then(({ result }) => {
          createPlanModal.loading = false;
          createPlanModal.visible = false;
          if (
            createPlanModal.selectedWarehouseType ==
            warehouseTypeEnum.storageWarehouse
          ) {
            router.push({
              name: "storage_inbound_detail",
              params: { id: result?.inPlanId },
            });
          } else if (
            createPlanModal.selectedWarehouseType ==
            warehouseTypeEnum.consignmentWarehouse
          ) {
            router.push({
              name: "consignment_inbound_confirm",
              params: { id: result.inPlanId },
            });
          }
        })
        .catch((e) => {
          createPlanModal.loading = false;
        });
    };

    const handleGetWarehouseInfos = () => {
      createPlanModal.selectedTargetWarehouse = null;
      let searchData = {
        warehouseType: createPlanModal.selectedWarehouseType,
        isActive: true,
      };
      createPlanModal.targetWarehousesLoading = true;
      return getWarehouses(searchData)
        .then((res) => {
          createPlanModal.targetWarehousesLoading = false;
          createPlanModal.targetWarehouses = res.result;
        })
        .catch(() => {
          createPlanModal.targetWarehousesLoading = false;
        });
    };

    const rules = {
      selectedWarehouseType: [
        {
          required: true,
          validator: (rule, value) => {
            if (createPlanModal.selectedWarehouseType) {
              return Promise.resolve();
            }
            return Promise.reject();
          },
          whitespace: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.warehouse_type"),
            ]);
          },
        },
      ],
      selectedTargetWarehouse: [
        {
          required: true,
          whitespace: true,
          message: () => {
            return vueI18n.t("common.p0_is_required", [
              vueI18n.t("warehouse.target_warehouse"),
            ]);
          },
        },
      ],
    };

    //----------------------创建入库计划Modal end--------------------------------------- //

    const disabledPrintBill = computed(() => {
      let result = false;
      for (let i = 0; i < state.selectedRows.length; i++) {
        if (state.selectedRows[i].status < transportStatusEnum.awaitPay) {
          result = true;
          break;
        }
      }
      return result;
    })

    onMounted(async () => {
      funcGetCountrys();
    });

    onActivated(() => {
      handleSearch();
    });

    return {
      columns,
      planDraftStatus,
      ...toRefs(state),
      cmCubicToM,
      gToKg,
      warehouseTypeEnum,
      destinationTypeEnum,
      transportStatusEnum,
      logisticsWayEnum,
      logisticsAccountingPeriodCalculationWayEnum,
      logisticsCalcMethodEnum,
      tableTotalState,
      getTotalCostStr,
      handleExportWarehouseReceipt,
      handlePrintBill,
      handleExportBill,
      onTableSelectedChange,
      tableColumnTitle,
      handleSelectedDate,
      handleExport,
      handleImport,
      handlePage,
      handleSearch,
      handleCreatePlan,
      getLanguageName,
      getAddress,
      handleSearchCities,
      handleChange,

      trackModal,
      handleShowTrackModal,

      handleEditOrDetails,
      handleMenuClick,

      createPlanModal,
      rules,
      handleShowCreatePlanModal,
      handleCreatePlanModalOk,
      handleGetWarehouseInfos,
      refForm,
      disabledPrintBill,
    };
  },
});
</script>

<style lang="less" scoped>
.total-cost {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.total-cost-item+.total-cost-item::before{
  content: ', '
}
</style>
